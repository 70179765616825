/* Sizing + Spacing
========================================================================== */

html,
body {
  padding: 0;
  margin: 0;

  box-sizing: border-box;
}

body {
  min-height: 100vh;
}

* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}

/* Font Settings
========================================================================== */

html {
  font-size: 100%;
  font-family: Noto Sans, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  color: hsla(0, 0%, 0%, 0.8);
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}

@media only screen and (max-width: 1024px) {
  html {
    font-size: 95%;
  }
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 85%;
  }
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 75%;
  }
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.85028rem;
}

h6 {
  font-size: 0.78405rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 1rem;
  margin-top: 0;
}

p {
  line-height: 1.4;
}

/* Links
========================================================================== */

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

/* Buttons
========================================================================== */

button {
  cursor: pointer;
}
